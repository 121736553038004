.contact-table td:nth-child(2) {
    max-width: 15em !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .contact-table td:nth-child(3) {
    max-width: 10em !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

   
  .contact-table td:nth-child(4) {
    max-width: 10em !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  tbody + thead{
    display: none;
  }

  .modal-dialog{
    padding-left: 4rem;
  }

