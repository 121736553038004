$break-small: 320px;
$break-middle: 425px;
$break-large: 1024px;

@mixin respond-to($media) {
    @if $media = handhelds {
        @media only screen and (min-width: $break-small) and (max-width: $break-middle){
            @content;
        }
    }
    @else if $media == medium-screens {
        @media only screen and (min-width: $break-small +1 ) and (max-width: $break-large -1) {
            @content;
            
        }
    } 
    @else if $media == wide-screens {
        @media only screen and (min-width: $break-large) {
            @content;
            
        }
    }
    
}