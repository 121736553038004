@import "../../../../responsive";

.copy{
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    position: relative;
    top: 0px;
    vertical-align: bottom;
    z-index: 1;
    height: 40px;
    margin-right: -10px;
    padding: 10px;
    width: 40px;
    fill: white;
}

.btn .noborder { 
    border: none;
}
.media-pagination{
    float: right;
}

.sample-img {
    width: 70%;
    margin: 10px auto;
    display: grid;
    place-items: center;
    @include respond-to(mid-screens) {
      margin: 80px auto;
    }
  
    img {
      width: 70%;
      height: auto;
      @include respond-to(mid-screens) {
        width: 95%;
      }
      @include respond-to(handhelds) {
        width: 89%;
      }
    }
    @include respond-to(mid-screens) {
      width: 95%;
    }
    @include respond-to(handhelds) {
      width: 95%;
    }
}