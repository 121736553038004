.copy{
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    position: relative;
    top: 0px;
    vertical-align: bottom;
    z-index: 1;
    height: 40px;
    margin-right: -10px;
    padding: 10px;
    width: 40px;
    fill: white;
}

.btn .noborder { 
    border: none;
}
.media-pagination{
    float: right;
}